<template>
	<div class="px-4 login_bg">
		<div class="wrapper">
			<v-container
				class="pa-0"
				fluid
			>
				<v-col
					cols="12"
					align="center"
					class="pa-0 mb-10 layout_align login"
				>
					<v-col
						cols="12"
						align="center"
						class="pa-0"
					>
						<div class="content_title">
							<div class="mb-3">휴면 계정 해제</div>
							본인확인
						</div>
					</v-col>

					<v-col
						cols="12"
						class="pa-4 pa-sm-6 section"
					>
						<!-- 아이디찾기 or 비밀번호찾기 선택 -->
						<v-col
							cols="12"
							class="pa-0 mb-7"
						>
						</v-col>

						<v-col
							cols="12"
							class="pa-0"
						>
							<p class="mb-5 notice">
								가입 시 입력했던 이름및 아이디와 휴대폰 번호를 통해 본인 확인을 진행헤주세요.<br />
								아이디가 생각이 나지 않을 시 아이디 찾기를 진행해주세요.
							</p>
							<CommonInputsInput05
								v-model="name"
								height="42"
								class="mb-2"
								placeholder="이름"
							/>
							<CommonInputsInput05
								v-model="email"
								height="42"
								:placeholder="email"
								class="mb-2 placeholder-color"
								readonly
							/>
							<v-row class="ma-0">
								<v-col
									cols="8"
									class="pa-0 pr-1"
								>
									<CommonInputsInput05
										v-model="hand"
										height="42"
										type="number"
										placeholder="휴대폰번호(-없이 숫자만)"
									/>
								</v-col>
								<v-col
									cols="4"
									class="pa-0"
								>
									<CommonButtonsButton01
										class="btn_sms_num"
										:disabled="hand === null"
										name="인증번호 발송"
										:color="smsNumberCheck ? 'primary' : '#262626'"
										height="42"
										@click="checkSms"
									/>
								</v-col>
								<p
									v-if="smsNumberCheck"
									class="confirm_txt"
								>
									인증되었습니다.
								</p>
								<CommonLoaderLine01
									v-if="telLoading"
									class="my-3"
								/>
							</v-row>
							<div
								v-if="smsNumberModel"
								class="mt-2"
							>
								<v-row class="ma-0">
									<v-col
										class="pa-0"
										cols="8"
									>
										<div class="pr-1">
											<CommonInputsInput02
												v-model="smsNum"
												height="42"
												placeholder="인증번호를 입력"
											/>
										</div>
									</v-col>
									<v-col
										class="pa-0"
										cols="4"
									>
										<div>
											<CommonButtonsButton01
												name="인증 확인"
												color="#262626"
												class="btn_sms_num"
												height="42"
												@click="confirmSmsNumber"
											/>
										</div>
										<v-col
											cols="12"
											class="pa-0 pr-2 pt-1"
											align="end"
											style="color: #fa2a6f; font-size: 12px"
										>
											{{ sms_check_timer }}
										</v-col>
									</v-col>
								</v-row>
							</div>
							<CommonButtonsButton01
								:disabled="allowValue"
								name="휴면계정 해제"
								color="#78C046"
								height="50"
								class="mt-8 mb-4 btn_find"
								@click="onApiCallFind('activelogin')"
							/>
							<!-- <v-btn
								text
								class="btn_login"
								@click="$router.push('/login')"
								>로그인하기 〉
							</v-btn> -->
						</v-col>
					</v-col>
				</v-col>
			</v-container>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import sms from '@/mixins/sms'

export default {
	name: 'LoginActiveMember',
	metaInfo: {
		title: '휴면 계정 해제 | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '휴면 계정 해제 | 동화자연마루',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루 휴면 계정 해제',
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	mixins: [sms],
	data: () => ({
		checkbox: false,
		name: null,
		hand: null,
		email: null,
		smsNum: null,
		// sms
		smsNumberCheck: null,
		smsNumberModel: false,
		telLoading: false,
	}),
	computed: {
		getQuery() {
			return this.$route.params.id
		},
		allowValue() {
			let checkValue = []
			checkValue = ['name', 'hand', 'email', 'smsNumberCheck']

			for (let i of checkValue) {
				if (this[i] === null || this[i] === false) return true
			}

			return false
		},
	},
	watch: {
		hand(val) {
			if (val === null) this.idCheck = null
		},
		sms_check_timer(val) {
			if (val === '00:00') {
				this.smsNumberCheck = null
				this.smsNumberModel = false
			}
		},
	},
	created() {},
	mounted() {
		//this.tab = Number(this.$route.query.tab ?? 0) //병합연산자
		this.email = this.$route.query.loginId
		console.log('email:', this.email)
	},

	methods: {
		...mapActions(['AUTH_ACT_ACTIVE_INFO']),
		async checkSms() {
			this.telLoading = true
			let params = {
				type: this.sms_check_type[4],
				hand: this.hand,
				name: this.name,
				login_id: this.email,
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		async onApiCallFind(type) {
			let params = {}

			params = {
				member_kind: this.getQuery === '0' ? 1 : 3,
				name: this.name,
				login_id: this.email,
				regi_userid: this.email,
				hand: this.hand,
			}

			// if (type === 'id') {
			// 	params = {
			// 		member_kind: this.getQuery === '0' ? 1 : 3,
			// 		name: this.name,
			// 		hand: this.hand,
			// 	}
			// } else {
			// 	params = {
			// 		member_kind: this.getQuery === '0' ? 1 : 3,
			// 		name: this.name,
			// 		login_id: this.email,
			// 		regi_userid: this.email,
			// 		hand: this.hand,
			// 	}
			// }

			const items = { method: type, params: params }

			await this.AUTH_ACT_ACTIVE_INFO(items).then(res => {
				console.log('res', res)
				if (res) {
					//this.$router.replace('/login/answer_activeinfo').catch(() => {})
					this.$router.push('/login/answer_activeinfo').catch(() => {})
				} else {
					this.$toastr.error(
						'요청하신 정보와 일치한 회원 정보를 찾을 수 없습니다. 다시 한번 확인해 주시기 바랍니다',
						'휴면 계정 해제 오류',
						{ timeOut: 2500 },
					)
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.login_bg {
	background-color: $color_gray_1;
	height: 100%;
}
.login {
	max-width: 400px !important;
}
.section {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.notice {
	text-align: left;
	font-size: $font_normal;
	color: $color_gray_5;
	word-break: keep-all;
}

.placeholder-color::v-deep .v-input__slot fieldset {
	border: 1px solid $color_gray_4 !important;
}
.placeholder-color::v-deep input::placeholder {
	color: #262626 !important;
}

.confirm_txt {
	font-size: $font_sm;
	color: #00ce7d;
}

.btn_find {
	:deep(.v-btn__content) {
		color: $color_white;
		font-weight: $fw_bold;
		font-size: $font_lg;
	}
}

.btn_sms_num {
	border-radius: 4px;
	:deep(.v-btn__content) {
		color: $color_white;
		font-size: $font_mid;
	}
}

.btn_login {
	:deep(.v-btn__content) {
		color: $color_gray_7;
		font-size: $font_normal;
		font-weight: $fw_regular;
		border-bottom: 1px solid $color_gray_7;
	}
}
</style>
