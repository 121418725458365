<template>
	<div class="px-4 login_bg">
		<div class="wrapper">
			<v-container
				class="pa-0"
				fluid
			>
				<v-col
					cols="12"
					align="center"
					class="pa-0 mb-10 layout_align login"
				>
					<v-col
						cols="12"
						align="center"
						class="pa-0"
					>
						<div class="content_title">
							<div
								v-if="getQuery === '0'"
								class="mb-3"
							>
								일반 회원
							</div>
							<div
								v-if="getQuery === '1'"
								class="mb-3"
							>
								인테리어 회원
							</div>
							아이디/비밀번호 찾기
						</div>
					</v-col>

					<v-col
						cols="12"
						class="pa-4 pa-sm-6 section"
					>
						<!-- 아이디찾기 or 비밀번호찾기 선택 -->
						<v-col
							cols="12"
							class="pa-0 mb-7"
						>
							<CommonTabsTabLogin
								v-model="tab"
								:tab-items="tabItems"
							/>
						</v-col>

						<v-col
							cols="12"
							class="pa-0"
						>
							<v-tabs-items v-model="tab">
								<!-- 찾기(아이디) -->
								<v-tab-item>
									<p class="mb-5 notice">
										가입 시 입력했던 이름과 휴대폰 번호를 입력해 주세요.<br />
										정보를 입력하면 이메일(아이디)을 알려드립니다.
									</p>
									<CommonInputsInput05
										ref="inputName0"
										v-model="name"
										:height="42"
										class="mb-2"
										placeholder="이름"
										:readonly="smsNumberCheck"
										:clearable="!smsNumberCheck"
									/>
									<v-row class="ma-0">
										<v-col
											cols="8"
											class="pa-0 pr-1"
										>
											<CommonInputsInput05
												ref="inputHand0"
												v-model="hand"
												:height="42"
												placeholder="휴대폰번호(-없이 숫자만)"
												:readonly="smsNumberCheck"
												:clearable="!smsNumberCheck"
											/>
										</v-col>
										<v-col
											cols="4"
											class="pa-0"
										>
											<CommonButtonsButton01
												class="btn_sms_num"
												:disabled="hand === null"
												name="인증번호 발송"
												:color="smsNumberCheck ? 'primary' : '#262626'"
												:height="42"
												@click="
													getQuery === '0' ? checkSms(sms_check_type[0]) : checkSms(sms_check_type[2])
												"
											/>
										</v-col>
										<v-row class="ma-0">
											<v-col
												class="pa-0 pt-1"
												cols="8"
												align-self="center"
												align="start"
											>
												<p
													v-if="smsNumberCheck"
													class="confirm_txt"
												>
													인증되었습니다.
												</p>
											</v-col>
											<v-col
												class="pa-0 pt-1"
												cols="4"
												align-self="center"
												align="end"
											>
												<v-btn
													v-if="smsNumberCheck"
													class="pa-0 reset_btn"
													text
													@click="resetSms"
												>
													<v-icon
														color="#9e9e9e"
														small
														>mdi-refresh</v-icon
													>
													초기화
												</v-btn>
											</v-col>
										</v-row>
										<CommonLoaderLine01
											v-if="telLoading"
											class="my-3"
										/>
									</v-row>

									<div
										v-if="smsNumberModel"
										class="mt-2"
									>
										<v-row class="ma-0">
											<v-col
												class="pa-0"
												cols="8"
											>
												<div class="pr-1">
													<CommonInputsInput02
														ref="inputSmsNum0"
														v-model="smsNum"
														:height="42"
														type="number"
														placeholder="인증번호를 입력"
													/>
												</div>
											</v-col>
											<v-col
												class="pa-0"
												cols="4"
											>
												<div>
													<CommonButtonsButton01
														name="인증 확인"
														color="#262626"
														class="btn_sms_num"
														:height="42"
														@click="confirmSmsNumber"
													/>
												</div>
												<v-col
													cols="12"
													class="pa-0 pr-2 pt-1"
													align="end"
													style="color: #fa2a6f; font-size: 12px"
												>
													{{ sms_check_timer }}
												</v-col>
											</v-col>
										</v-row>
									</div>

									<CommonButtonsButton01
										:disabled="allowValue"
										name="이메일(아이디) 찾기"
										color="#78C046"
										:height="50"
										class="mt-8 mb-4 btn_find"
										@click="onApiCallFind('id')"
									/>

									<v-btn
										text
										class="btn_login"
										@click="$router.push('/login')"
										>로그인하기 〉
									</v-btn>
								</v-tab-item>

								<!-- 찾기(비밀번호) -->
								<v-tab-item>
									<p class="mb-5 notice">
										가입 시 입력했던 이름, 이메일 및 휴대폰 번호를 입력해주세요.<br />
										정보를 입력하면 임시 비밀번호를 발급해드립니다.
									</p>
									<CommonInputsInput05
										ref="inputName1"
										v-model="name"
										:height="42"
										class="mb-2"
										placeholder="이름"
										:readonly="smsNumberCheck"
										:clearable="!smsNumberCheck"
									/>
									<CommonInputsInput05
										ref="inputEmail1"
										v-model="email"
										:height="42"
										placeholder="이메일(아이디)"
										class="mb-2"
										:readonly="smsNumberCheck"
										:clearable="!smsNumberCheck"
									/>
									<v-row class="ma-0">
										<v-col
											cols="8"
											class="pa-0 pr-1"
										>
											<CommonInputsInput05
												ref="inputHand1"
												v-model="hand"
												:height="42"
												placeholder="휴대폰번호(-없이 숫자만)"
												:readonly="smsNumberCheck"
												:clearable="!smsNumberCheck"
											/>
										</v-col>
										<v-col
											cols="4"
											class="pa-0"
										>
											<CommonButtonsButton01
												class="btn_sms_num"
												:disabled="hand === null"
												name="인증번호 발송"
												:color="smsNumberCheck ? 'primary' : '#262626'"
												:height="42"
												@click="
													getQuery === '0' ? checkSms(sms_check_type[1]) : checkSms(sms_check_type[3])
												"
											/>
										</v-col>
										<v-row class="ma-0">
											<v-col
												class="pa-0 pt-1"
												cols="8"
												align-self="center"
												align="start"
											>
												<p
													v-if="smsNumberCheck"
													class="confirm_txt"
												>
													인증되었습니다.
												</p>
											</v-col>
											<v-col
												class="pa-0 pt-1"
												cols="4"
												align-self="center"
												align="end"
											>
												<v-btn
													v-if="smsNumberCheck"
													class="pa-0 reset_btn"
													text
													@click="resetSms"
												>
													<v-icon
														color="#9e9e9e"
														small
														>mdi-refresh</v-icon
													>
													초기화
												</v-btn>
											</v-col>
										</v-row>
										<CommonLoaderLine01
											v-if="telLoading"
											class="my-3"
										/>
									</v-row>
									<div
										v-if="smsNumberModel"
										class="mt-2"
									>
										<v-row class="ma-0">
											<v-col
												class="pa-0"
												cols="8"
											>
												<div class="pr-1">
													<CommonInputsInput02
														ref="inputSmsNum1"
														v-model="smsNum"
														:height="42"
														placeholder="인증번호를 입력"
													/>
												</div>
											</v-col>
											<v-col
												class="pa-0"
												cols="4"
											>
												<div>
													<CommonButtonsButton01
														name="인증 확인"
														color="#262626"
														class="btn_sms_num"
														:height="42"
														@click="confirmSmsNumber"
													/>
												</div>
												<v-col
													cols="12"
													class="pa-0 pr-2 pt-1"
													align="end"
													style="color: #fa2a6f; font-size: 12px"
												>
													{{ sms_check_timer }}
												</v-col>
											</v-col>
										</v-row>
									</div>
									<CommonButtonsButton01
										:disabled="allowValue"
										name="비밀번호 찾기"
										color="#78C046"
										:height="50"
										class="mt-8 mb-4 btn_find"
										@click="onApiCallFind('password')"
									/>
									<v-btn
										text
										class="btn_login"
										@click="$router.push('/login')"
										>로그인하기 〉
									</v-btn>
								</v-tab-item>
							</v-tabs-items>
						</v-col>
					</v-col>
				</v-col>
			</v-container>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import sms from '@/mixins/sms'

export default {
	name: 'LoginFindMember',
	metaInfo: {
		title: '아이디/비밀번호 찾기 | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '아이디/비밀번호 찾기 | 동화자연마루',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루 아이디/비밀번호 찾기',
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	mixins: [sms],
	data: () => ({
		tab: 0,
		tabItems: [
			{
				name: '아이디 찾기',
			},
			{
				name: '비밀번호 찾기',
			},
		],
		checkbox: false,
		name: null,
		hand: null,
		email: null,
		smsNum: null,
		// sms
		smsNumberCheck: null,
		smsNumberModel: false,
		telLoading: false,
	}),
	computed: {
		getQuery() {
			return this.$route.params.id
		},
		allowValue() {
			let checkValue = []

			if (this.tab === 0) {
				checkValue = ['name', 'hand', 'smsNumberCheck']
			} else {
				checkValue = ['name', 'hand', 'email', 'smsNumberCheck']
			}

			for (let i of checkValue) {
				if (this[i] === null || this[i] === false) return true
			}

			return false
		},
	},
	watch: {
		hand(val) {
			if (val === null) this.idCheck = null
		},
		sms_check_timer(val) {
			if (val === '00:00') {
				this.smsNumberCheck = null
				this.smsNumberModel = false
			}
		},
	},
	mounted() {
		this.tab = Number(this.$route.query.tab ?? 0) //병합연산자
		console.log('findinfoTab:', this.tab)
	},

	methods: {
		...mapActions(['AUTH_ACT_FIND_INFO']),
		async checkSms(type) {
			this.telLoading = true
			let params = {
				type: type,
				hand: this.hand,
				name: this.name,
				login_id: this.email,
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		async onApiCallFind(type) {
			let params = {}

			if (type === 'id') {
				params = {
					member_kind: this.getQuery === '0' ? 1 : 3,
					name: this.name,
					hand: this.hand,
				}
			} else {
				params = {
					member_kind: this.getQuery === '0' ? 1 : 3,
					name: this.name,
					login_id: this.email,
					regi_userid: this.email,
					hand: this.hand,
				}
			}

			const items = { method: type, params: params }

			await this.AUTH_ACT_FIND_INFO(items).then(res => {
				console.log('res', res)
				if (res) {
					if (type === 'id') return this.$router.replace('/login/answer_id').catch(() => {})
					this.$router.replace('/login/answer_password').catch(() => {})
				} else {
					this.$toastr.error(
						'요청하신 정보와 일치한 회원 정보를 찾을 수 없습니다. 다시 한번 확인해 주시기 바랍니다',
						'회원정보 찾기 오류',
						{ timeOut: 2500 },
					)
				}
			})
		},
		resetSms() {
			this.smsNumberCheck = false
			this.name = null
			this.hand = null
			this.email = null
			this.smsNum = null
			if (this.$refs.inputName0) this.$refs.inputName0.inputModel = ''
			if (this.$refs.inputHand0) this.$refs.inputHand0.inputModel = ''
			if (this.$refs.inputSmsNum0) this.$refs.inputSmsNum0.inputModel = ''
			if (this.$refs.inputName1) this.$refs.inputName1.inputModel = ''
			if (this.$refs.inputHand1) this.$refs.inputHand1.inputModel = ''
			if (this.$refs.inputEmail1) this.$refs.inputEmail1.inputModel = ''
			if (this.$refs.inputSmsNum1) this.$refs.inputSmsNum1.inputModel = ''
		},
	},
}
</script>

<style lang="scss" scoped>
.login_bg {
	background-color: $color_gray_1;
	height: 100%;
}
.login {
	max-width: 400px !important;
}
.section {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.notice {
	text-align: left;
	font-size: $font_normal;
	color: $color_gray_5;
	word-break: keep-all;
}

.confirm_txt {
	font-size: $font_sm;
	color: #00ce7d;
	text-align: left;
}

:deep(.v-btn.reset_btn) {
	min-height: unset !important;
	min-width: unset !important;
	:deep(.v-btn__content) {
		font-size: $font_mid;
		color: $color_gray_5;
	}
}

.btn_find {
	:deep(.v-btn__content) {
		color: $color_white;
		font-weight: $fw_bold;
		font-size: $font_lg;
	}
}

.btn_sms_num {
	border-radius: 4px;
	:deep(.v-btn__content) {
		color: $color_white;
		font-size: $font_mid;
	}
}

.btn_login {
	:deep(.v-btn__content) {
		color: $color_gray_7;
		font-size: $font_normal;
		font-weight: $fw_regular;
		border-bottom: 1px solid $color_gray_7;
	}
}
.v-input--is-readonly::v-deep .v-input__slot fieldset {
	border: 1px solid $color_gray_4 !important;
}
</style>
